import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ListCheesesIndexBlock as BlockData,
  WediaImage,
} from '../../../graphql/generated/api-graphql'
import TableIndexCheeses, {
  TableIndexCheesesProps,
} from '../../../components/molecules/TableIndexCheeses'
import { BlockProps } from '../props'
import { Icons } from '../../../components/atoms/Icon'
import Router, { routes } from '../../../routes/Router'
import FormFieldCheckbox from '../../../components/form/fields/FormFieldCheckbox'

export type ListCheesesIndexBlockProps = BlockProps & {
  data: BlockData
  aopImage?: WediaImage
}
const ListCheesesIndexBlock: FC<ListCheesesIndexBlockProps> = ({ data }) => {
  const { t } = useTranslation()

  const [values, setValues] = useState({
    milk: data?.filters?.milk?.map((entry: any) => entry?.id + ''),
    region: data?.filters?.region?.map((entry: any) => entry?.id + ''),
    type: data?.filters?.type?.map((entry: any) => entry?.id + ''),
    intensity: data?.filters?.intensity?.map((entry: any) => entry?.id + ''),
  })

  const filtersArray: {
    name: string
    options: { label: string; value: string }[]
    all: { label: string; value: string }
  }[] = useMemo(
    () =>
      ['milk', 'region', 'type', 'intensity']?.map((name) => ({
        name,
        options:
          data?.filters?.[name]?.map((entry: any) => ({
            label: entry.name,
            value: entry.id + '',
          })) ?? [],
        all: {
          label: t(`listCheese_filter_${name}_all`),
          value: '',
        },
      })),
    [t, data?.filters]
  )

  const indexProps: TableIndexCheesesProps = {
    cheeses: data?.indexCheeses
      ?.filter(
        (cheese) =>
          values?.milk?.includes(cheese.tagMilkId) &&
          values?.intensity?.includes(cheese.tagIntensityId) &&
          values?.region?.includes(cheese.tagRegionId) &&
          values?.type?.includes(cheese.tagTypeId)
      )
      ?.map((cheese) => ({
        name: cheese?.title,
        typeLait: cheese?.tagMilk ?? '',
        origin: cheese?.tagRegion ?? '',
        typePate: cheese?.tagType ?? '',
        gout: cheese?.tagIntensity ?? '',
        link: Router.getRouteUrl(routes.cheese, { slug: cheese?.slug }),
      })),
    formikForm: {
      initialValues: values,
      onSubmit: (values) => console.log(values),
      onContextUpdate: (context) => setValues(context.values),
      validateOnChange: true,
    },
    cheesesLabel: t('cheesesIndex_cheese_label'),
    dropdowns:
      filtersArray?.map((filter) => ({
        dropdownHead: {
          icon:
            {
              milk: Icons.milk,
              region: Icons.location,
              type: Icons.cheese,
              intensity: Icons.level,
            }[filter?.name] ?? undefined,
          title: t(`listCheese_filter_${filter?.name}_label`),
        },
        allFiltersProps: {
          name: 'all',
          ...filter?.all,
        },
        name: filter?.name ?? '',
        colorIcon: true,
        fields: filter?.options?.map((option) => ({
          checkboxes: {
            Component: FormFieldCheckbox,
            type: 'checkbox',
            ...option,
          },
        })),
      })) ?? [],
  }

  return <TableIndexCheeses {...indexProps} />
}

export default ListCheesesIndexBlock
