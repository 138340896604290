import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import tracking from 'src/tracking'
import { useRouter } from 'next/router'

import {
  ArticleUnion,
  CulinaryArticleFourItemsBlock as Data,
  PageTemplate,
  Tag,
} from '../../../graphql/generated/api-graphql'
import CardsBlock, {
  CardsBlockProps,
} from '../../../components/molecules/CardsBlock'
import { transformTagForCloud } from '../../Common/tag'
import { transformArticleBigCard } from '../../ArticleCard/transformArticleBigCard'
import { transformArticleCard } from '../../ArticleCard/transformArticleCard'
import { Icons } from '../../../components/atoms/Icon'
import { BlockProps } from '../props'
import { isHomePage } from '../../Common/routes'

export type CulinaryArticleFourItemsBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}
const CulinaryArticleFourItemsBlock: FC<CulinaryArticleFourItemsBlockProps> = ({
  data,
  template,
  pagesByTemplate,
}) => {
  const router = useRouter()
  const { t } = useTranslation()

  console.log('data?.articleUnions', data?.culinaryArticles)

  const props: CardsBlockProps = {
    title: data?.title ?? '',
    tags: data?.tags?.map((tag: Tag) =>
      transformTagForCloud(tag, `${template} - ${data.title}`)
    ),
    highlighted: transformArticleBigCard(
      t,
      data?.culinaryArticles?.[0],
      data?.title ?? undefined,
      isHomePage(router.pathname)
    ),
    cards: data?.culinaryArticles
      ?.slice(1)
      ?.map((item: ArticleUnion) =>
        transformArticleCard(
          t,
          item,
          data?.title ?? undefined,
          isHomePage(router.pathname)
        )
      ),
    actionButtonProps: {
      href: pagesByTemplate?.[PageTemplate.HpRecipes]?.slug,
      onClick: () =>
        tracking.cta(t('see_more_articles'), data?.title ?? undefined),
      label: t('see_more_articles'),
      iconPosition: 'right',
      iconProps: {
        icon: Icons.arrowRight,
      },
    },
  }

  return <CardsBlock {...props} />
}

export default CulinaryArticleFourItemsBlock
