import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import tracking from 'src/tracking'

import {
  CouponsBlock as Data,
  HighcoCoupon,
} from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'
import { CouponCardsBlockProps } from '../../../components/molecules/CouponCardsBlock'
import { useServiceRequest } from '../../../hooks/useServiceRequest'
import { actions, selectors } from '../../../redux'
import {
  cartCouponTransformer,
  couponTransformer,
  replaceRayonLabel,
} from '../../Coupons/couponTransformer'
import CouponsListBlock from '../../../components/molecules/CouponsListBlock'
import ReductionsList, {
  ReductionsListProps,
} from '../../../components/molecules/ReductionsList'
import ActionButtonBlock, {
  ActionButtonBlockProps,
} from '../../../components/molecules/ActionButtonBlock'
import { sortOnString } from '../../../helpers/ArrayHelpers'
import { InformationPointsProps } from '../../../components/molecules/InformationPoints'
import { Icons } from '../../../components/atoms/Icon'
import { ActionButtonVariant } from '../../../components/atoms/ActionButton/styled'
import Router, { routes } from '../../../routes/Router'
import { actionButtonMock } from '../../../components/atoms/ActionButton/mocks'

export type CouponsBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}
const CouponsBlock: FC<CouponsBlockProps> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isInit = useSelector(selectors.app.isInit)
  const isConnected = useSelector(selectors.auth.isConnected)
  const authStatusIsComputed = useSelector(selectors.auth.authStatusIsComputed)
  const [isActionPending, setIsActionPending] = useState(false)

  const [coupons, loadCoupons] = useServiceRequest(
    selectors.coupons.coupons,
    actions.coupons.couponsRequest,
    actions.coupons.couponsReset
  )
  const cart = useSelector(selectors.coupons.cart)
  const [addingState, setAddingState] = useState<string[]>([])
  const [removingState, setRemovingState] = useState<string[]>([])
  const selectedCoupons = useMemo(
    () => cart?.listCoupon?.map((c) => c?.couponCode ?? '') ?? [],
    [cart]
  )

  const cartList: HighcoCoupon[] = cart?.listCoupon ?? []

  const [, loadCart] = useServiceRequest(
    selectors.coupons.getCart,
    actions.coupons.getCartRequest,
    actions.coupons.getCartReset
  )

  const [addToCart, handleAddToCart] = useServiceRequest(
    selectors.coupons.addToCart,
    actions.coupons.addToCartRequest,
    actions.coupons.addToCartReset
  )
  const [removeToCart, handleRemoveFromCart] = useServiceRequest(
    selectors.coupons.removeFromCart,
    actions.coupons.removeFromCartRequest,
    actions.coupons.removeFromCartReset
  )
  const [print, handlePrint] = useServiceRequest(
    selectors.coupons.printCart,
    actions.coupons.printCartRequest,
    actions.coupons.printCartReset
  )

  const isLoadingCoupons = useMemo(
    () => coupons?.pending || !isInit,
    [coupons, isInit]
  )

  useEffect(() => {
    setAddingState((before) =>
      before.filter((c) => !selectedCoupons?.includes(c))
    )
    if (!removeToCart?.pending) {
      setRemovingState([])
    }
  }, [removeToCart?.pending, removingState.length, selectedCoupons])

  useEffect(() => {
    if (print?.data) {
      window.location.href = print?.data
      dispatch(actions.coupons.printCartReset())
    }
  }, [dispatch, print?.data])

  useEffect(() => {
    if (addToCart.pending || removeToCart.pending || print.pending) {
      setIsActionPending(true)
    } else {
      setIsActionPending(false)
    }
  }, [addToCart.pending, print.pending, removeToCart.pending])

  const onSelect = useCallback(
    (coupon?: HighcoCoupon) => {
      if (isConnected) {
        if (selectedCoupons?.includes(coupon?.couponCode ?? '')) {
          handleRemoveFromCart({
            couponCode: coupon?.couponCode ?? '',
          })
          setRemovingState((before) => [...before, coupon?.couponCode ?? ''])
        } else {
          handleAddToCart({
            couponCode: coupon?.couponCode ?? '',
          })
          setAddingState((before) => [...before, coupon?.couponCode ?? ''])
        }
      } else {
        dispatch(actions.auth.setFullAuthPopinOpen(true))
      }
    },
    [
      dispatch,
      handleAddToCart,
      handleRemoveFromCart,
      isConnected,
      selectedCoupons,
    ]
  )

  const [filterState, setFilterState] = useState({ brand: '', rayon: '' })

  const couponsCopy = Array.from([...(coupons?.data ?? [])])

  const filteredResults = couponsCopy?.filter(
    (coupon: HighcoCoupon) =>
      (!filterState.brand || coupon.brandId + '' === filterState.brand) &&
      (!filterState.rayon ||
        coupon.listRayon.some((r) => r.id + '' === filterState.rayon))
  )

  const brandOptions: { label: string; value: string }[] = useMemo(
    () =>
      isInit && coupons?.data?.length > 0
        ? (coupons?.data as HighcoCoupon[])
            ?.reduce((acc, coupon) => {
              if (!acc.some((b) => coupon?.brandId + '' === b.value)) {
                acc.push({
                  label: coupon.brandName as string,
                  value: coupon.brandId + '',
                })
              }
              return acc
            }, [] as { label: string; value: string }[])
            ?.sort(sortOnString('label'))
        : [],
    [coupons?.data, isInit]
  )
  const rayonOptions: { label: string; value: string }[] = useMemo(
    () =>
      isInit && coupons?.data?.length > 0
        ? (coupons?.data as HighcoCoupon[])
            ?.reduce((acc, coupon) => {
              return acc.concat(
                (
                  coupon?.listRayon?.filter(
                    (r) => !acc.find((b) => r.id + '' === b.value)
                  ) ?? []
                )?.map((r) => ({
                  label: replaceRayonLabel(r.name ?? '', t),
                  value: r.id + '',
                }))
              )
            }, [] as { label: string; value: string }[])
            ?.sort(sortOnString('label'))
        : [],
    [coupons?.data, isInit, t]
  )

  const informationPointsProps: InformationPointsProps = {
    pointsList: [
      {
        title: t('coupons_infos_select'),
        pointIcon: { icon: Icons.coupons },
      },
      {
        title: t('coupons_infos_print'),
        pointIcon: { icon: Icons.print },
      },
      {
        title: t('coupons_infos_shop'),
        pointIcon: { icon: Icons.panier },
      },
    ],
  }

  const cardsBlockProps: CouponCardsBlockProps = {
    title: t('coupons_list_title'),
    cards: isInit
      ? filteredResults?.map((coupon: HighcoCoupon) => {
          const isLoading =
            addingState.includes(coupon.couponCode + '') ||
            removingState.includes(coupon.couponCode + '')
          return couponTransformer(
            t,
            coupon,
            selectedCoupons?.includes(coupon.couponCode + ''),
            () => onSelect(coupon),
            selectedCoupons,
            isLoading,
            isActionPending && !isLoading
          )
        })
      : [],
    filters: [
      {
        label: t('coupons_filters_brand_label'),
        staticLabel: true,
        name: 'brand',
        value: filterState.brand,
        onChange: (e) => {
          setFilterState((before) => ({
            ...before,
            [e.target.name]: e.target.value,
          })),
            tracking.filter(
              t('coupons_filters_brand_label'),
              brandOptions.find((el) => el.value === e.target.value)?.label
            )
        },
        placeholder: t('coupons_filters_brand_placeholder'),
        options: [
          { label: t('coupons_filters_brand_placeholder'), value: '' },
        ].concat(brandOptions),
        required: false,
      },
      {
        label: t('coupons_filters_rayon_label'),
        staticLabel: true,
        name: 'rayon',
        value: filterState.rayon,
        onChange: (e) => {
          setFilterState((before) => ({
            ...before,
            [e.target.name]: e.target.value,
          })),
            tracking.filter(
              t('coupons_filters_rayon_label'),
              rayonOptions.find((el) => el.value === e.target.value)?.label
            )
        },
        placeholder: t('coupons_filters_rayon_placeholder'),
        options: [
          { label: t('coupons_filters_rayon_placeholder'), value: '' },
        ].concat(rayonOptions),
        required: false,
      },
    ],
    isLoadingCoupons,
    noResultsMessage: t('coupons_no_results'),
  }

  const actionButtonBlockProps: ActionButtonBlockProps | undefined =
    !isConnected
      ? {
          actionButtonList: [
            {
              label: t('coupons_connexion_connect_label'),
              iconPosition: 'left',
              iconProps: {
                icon: Icons.user,
              },
              onClick: () => dispatch(actions.auth.setAuthPopinOpen(true)),
            },
            {
              label: t('coupons_connexion_register_label'),
              iconPosition: 'right',
              iconProps: {
                icon: Icons.arrowRight,
              },
              href: Router.getRouteUrl(routes.signup),
              variant: ActionButtonVariant.secondary,
            },
          ],
        }
      : undefined

  const total =
    Math.round(
      (cartList?.reduce(
        (total, entry) => total + (entry?.discountAmount ?? 0),
        0
      ) ?? 0) * 100
    ) / 100

  const reductionsListProps: ReductionsListProps = {
    sectionHeader: {
      title: t('coupons_selections_title'),
      count: cartList?.length ?? 0,
    },
    texts: {
      text: t('coupons_economy'),
      rising: t('pricing_price_in_EUR', { value: total }),
      risingIsNull: !total || total === 0 ? true : false,
    },
    reductionsList: cartList?.map((coupon) =>
      cartCouponTransformer(coupon, () => onSelect(coupon))
    ),
    buttonFooter: {
      ...actionButtonMock,
      label: t('coupons_print_all'),
      iconPosition: 'left',
      iconProps: {
        icon: Icons.print,
      },
      disabled: !isConnected,
      onClick: () => {
        if (isConnected) {
          handlePrint({})
          tracking.coupons.printAll(
            Math.round(
              (cartList?.reduce(
                (total, entry) => total + (entry?.discountAmount ?? 0),
                0
              ) ?? 0) * 100
            ) / 100,
            cartList.map((item) => {
              return {
                id: item?.couponId ?? undefined,
                name: item.label ?? undefined,
                brand: item.brandName ?? undefined,
                price: item.discountAmount ?? undefined,
              }
            })
          )
        }
      },
    },
  }

  // Load the coupons when we know about the user auth status (auth has been processed)
  useEffect(() => {
    if (authStatusIsComputed) {
      loadCoupons({})
    }
  }, [authStatusIsComputed, loadCoupons])

  useEffect(() => {
    if (isConnected) {
      loadCart({})
    }
  }, [isConnected, loadCart])

  return (
    <CouponsListBlock
      informationPointsProps={informationPointsProps}
      cardsBlock={cardsBlockProps}
      sideContent={
        <>
          {isInit && <ReductionsList {...reductionsListProps} />}
          {isInit && !isConnected && actionButtonBlockProps && (
            <ActionButtonBlock {...actionButtonBlockProps} />
          )}
        </>
      }
    />
  )
}

export default CouponsBlock
